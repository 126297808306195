import React, { Component } from 'react'
import Layout from '../layout/Layout'
import SideNav from '../components/SideNav'
import InfoIcon from '../images/information.svg'
import PhoneIcon from '../images/phoneIcon.svg'
import { graphql, Link } from 'gatsby'
import AutoSizingIframe from '../components/AutoSizingIframe'
import HardRightPhoto from '../images/hard-right.jpg'
import WooPhoto from '../images/wooo.jpg'
import BalloonImage from '../images/balloonImage.jpg'
import FlowersImage from '../images/flowers-image.jpg'
import GroupImage from '../images/groupImage.jpg'

import {
  FullWidthContainer,
  Header,
  Button,
  FooterCTA,
  FullWidthImage
} from '../components/Global'
import PillarIcon from '../images/icons/issues-iconset.svg'
import RecruitModal from '../components/RecruitModal'
import PostcodeSearch from '../components/webhtv/PostcodeSearch'

export default class Home extends Component {
  state = {
    targetMps: [
      {
        name: 'Tony Abbott',
        seat: 'Warringah',
        state: 'NSW'
      },
      {
        name: 'Peter Dutton',
        seat: 'Dickson',
        state: 'NSW'
      },
      {
        name: 'Christian Porter',
        seat: 'Pearce',
        state: 'WA'
      },
      {
        name: 'Nicolle Flint',
        seat: 'Boothby',
        state: 'SA'
      },
      {
        name: 'Greg Hunt',
        seat: 'Flinders',
        state: 'VIC'
      },
      {
        name: 'Kevin Andrews',
        seat: 'Menzies',
        state: 'VIC'
      }
    ],
    searchQuery: '',
    searchSuggestions: [],
    petitionLink:
      'https://www.getup.org.au/campaigns/federal-election-2018-19/election-home/join-us',
    recruitmentModule: {
      pageId: 20667,
      moduleId: 49821
    },
    volunteerModule: {
      pageId: 20667,
      moduleId: 49826
    }
  }

  renderHero = () => (
    <div className='hero-grid'>
      <img className='hero-grid-image' src={GroupImage} alt='GetUp members' />
      <div className='hero-grid-text-fw'>
        <h1 className='hero-grid-heading'>
          <span className='h2'>Election 2022:</span>
          <br />
          We Make the Future
        </h1>
        <p className='hero-grid-blurb h5 normal'>
          Join thousands of GetUp members fighting for a fair, flourishing and just
          Australia this election <br /><br />
          <a href='/howtovote' className='btn btn-secondary btn-large'>Get your issues based voting guide</a>
          <br />
        </p>
        {/* <PostcodeSearch lh={this.props.data.lh.nodes} uh={this.props.data.uh.nodes} /> */}

        {/* <RecruitModal
          module={this.state.recruitmentModule}
          buttonText='Volunteer now!'
          heading='Join the campaign'
          button='Count me in'
          className='btn-secondary btn-large btn-primary-recruit'
        /> */}
        {/* <Modal
          href={this.state.petitionLink}
          buttonText='Volunteer now!'
          className='btn-secondary btn-large btn-primary-recruit' /> */}
      </div>
    </div>
  )

  renderElectionPlan = () => (
    <>
      <FullWidthContainer className='grey-bg '>
        <Header className='orange' id='how-we-win'>
          How we win
        </Header>
        <p className='mt-3 mb-0 h5 normal'>
          Here's our ambitious plan to drive change for the better in the 2019 election.
        </p>
      </FullWidthContainer>

      <div className='checkerbox mb-3'>
        <img
          src={FlowersImage}
          className='checkerbox-image'
          alt='GetUp members handing out how to votes'
        />
        <div className='checkerbox-text-wrapper'>
          <h2 className='h3 checkerbox-heading'>
            <span>1</span>Kick out the hard right
          </h2>
          <p className='checkerbox-blurb'>
            A small group of hard right MPs have an inordinate influence over our
            politics. They have wrecked progressive policies and stifled public debate on
            climate change, refugees, multiculturalism, economics and democratic
            participation. We know we won’t move forward unless we remove them from power,
            so that’s what we’re going to do.
          </p>
        </div>
        <img
          src={HardRightPhoto}
          className='checkerbox-image'
          alt='GetUp member holding a sign'
        />
        <div className='checkerbox-text-wrapper'>
          <h2 className='h3 checkerbox-heading'>
            <span>2</span>People-powered campaigning
          </h2>
          <p className='checkerbox-blurb'>
            The best way to have the biggest possible electoral impact is through targeted
            and effective personal conversations with voters. Our goal for the campaign is
            to make a massive one million calls into target electorates. In addition,
            we'll be knocking on doors and handing out hundreds of thousands of How to
            Vote cards in our biggest Election Day yet.{' '}
          </p>
          {/* <Button href='#' className='btn-inline btn-secondary' arrowColor='#2098f5'>
                Read more
          </Button> */}
        </div>
        <img src={BalloonImage} className='checkerbox-image' alt='GetUp members' />
        <div className='checkerbox-text-wrapper'>
          <h2 className='h3 checkerbox-heading'>
            <span>3</span>Put our issues on the agenda
          </h2>
          <p className='checkerbox-blurb'>
            We want whoever forms government to deal with the issues that our movement
            cares about from day one, such as strong climate action and dignity for people
            seeking asylum. So we will put our issues on the agenda with such force that
            they cut through – in the press, and in people's lounge rooms and social media
            news feeds.
          </p>
          {/* <Button href='#' className='btn-inline btn-secondary' arrowColor='#2098f5'>
                Read more
          </Button> */}
        </div>
      </div>
    </>
  )

  render () {
    return (
      <Layout>
        {this.renderHero()}

        <div className='grid' style={{ position: 'relative', zIndex: '1' }}>
          <SideNav
            targetMps={this.state.targetMps}
            module={this.state.recruitmentModule}
          />
          <FullWidthContainer className='white-bg pt-0'>
            <Header className='orange'>What's the plan?</Header>
            <div className='home-pillar-icons'>
              <img src={PillarIcon} alt='Issue icons' />
            </div>
            <p className='h5 normal mb-4'>
              From the bushfires to the pandemic, the Morrison government has let many people down and left people behind.
            </p>
            <p className='h5 normal mb-4'>
              His leadership failed everyday people because he’s been working for his mining mates and the big end of town.
            </p>
            <p className='h5 normal mb-4'>
              Our national character is to help each other out, step in and support each other in times of need.  Communities have the solutions we need, but the Morrison government is not listening to everyday people.
            </p>
            {/* <Button href='#how-we-win' className='btn btn-inline' arrowColor='#ff671f'>
              Read more
            </Button> */}
            {/* <br />
            <br /> */}
          </FullWidthContainer>

          <FullWidthContainer className='htv-placeholder-container pb-5'>
            <div className='htv-placeholder'>
              <h2 className='htv-placeholder-heading white mb-2'>
                How to vote this election?
              </h2>
              <p className='htv-placeholder-blurb white h5 normal mb-4'>
                GetUp how-to-vote cards feature our 2022 campaign priority issues of First Nations justice, climate action and tackling inequality, alongside other long-standing GetUp campaigns, like fighting for a federal anti-corruption commission.
              </p>
              {/* <hr /> */}
              <a href='/howtovote' className='btn btn-secondary btn-large'>
                See how to vote cards
              </a>
              {/* <div className=' htv-placeholder-message p white mb-0'>
                <img src={InfoIcon} className='info-icon' alt='Info icon' />
                Our how to vote cards are still being finalised! Check back soon.</div> */}
            </div>
          </FullWidthContainer>

          <FullWidthImage>
            <img src={WooPhoto} alt='GetUp members' />
          </FullWidthImage>

          {/* {this.renderElectionPlan()} */}
          {/* <FullWidthContainer className='white-bg pt-0 pb-2 mt-5 mb-0 come-to-event'>
            <Header className='orange'>Join a calling party!</Header>
            <p className='h5 normal mb-3'>
              They're fun and sociable, and we'll provide all the support you need to get
              started.{' '}
              <b>Here's how we're tracking against our target of one million calls:</b>
            </p>
            <div className='progress-bar-container mb-5 mt-4'>
              <img src={PhoneIcon} className='phone-icon' alt='Phone icon' />
              <div className='progress-bar'>
                <span className='calls'>712,039</span>
                <span className='calling-target'>1,005,585</span>
              </div>
            </div>
            <p className='h5 normal'>
              Search your location to find a local calling party:
            </p>
          </FullWidthContainer> */}
        </div>
        {/* <div className='row collapse'>
          <div className='small-12 large-12 columns'>
            <AutoSizingIframe
              id='event-map'
              src='https://action.getup.org.au/electorate_event_calendar'
            />
          </div>
        </div> */}
        <FooterCTA href={this.state.petitionLink} module={this.state.recruitmentModule} />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    lh: allGoogleSpreadsheetHtvs {
      nodes {
        slug
        seat
        state
        issue
        title
        blurbHeading
        senateTitle
        senateBlurbHeading
      }
    }
    uh: allGoogleSpreadsheetHtvs(filter: { senateOnly: { eq: "TRUE" } }) {
      nodes {
        slug
        state
        issue
        title
      }
    }
  }
`
