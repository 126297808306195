import React from 'react'
import RecruitModal from '../components/RecruitModal'

const SideNav = (props) => (
  <aside>
    <div className='aside-wrapper'>
      <div className='aside-cta-wrapper mb-4'>
        <p style={{ fontSize: '0.9em' }} className='top-text mb-3'>
          <b>There are plenty of ways to get involved in the campaign</b> – from neighbourhood calling parties to handing out information to voters on election day.        </p>
        {/* <div className='btn-wrapper'>
          <p className='bold white mb-2'>Register your interest and one of our friendly staff or volunteers will be in touch.</p>
        </div> */}
        <RecruitModal
          module={props.module}
          buttonText='Count me in!'
          heading='Join the campaign'
          button='Count me in'
          className='' />
        {/* <Modal
          href={props.href}
          buttonText='Count me in!'
          className='' /> */}
      </div>
    </div>
  </aside>

)

export default SideNav
