import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Spinner from '../../images/spinner.svg'

export default class PostcodeSearch extends Component {
  state = {
    userPostCode: '',
    invalidPostCode: false,
    isLoading: false,
    allElectorates: [],
    allStates: []
  }
  componentDidMount () {
    this.addHtvsToState()
  }
  addHtvsToState = () => {
    const hiddenHTVs = [
      'dickson-housing',
      'menzies-climate',
      'qld-senate-housing',
      'sa-climate',
      'tas-senate-climate',
      'vic-senate-climate',
      'wa-senate-health',
      'nsw-senate-health'
    ]

    let allStates = this.props.lh
      .map(h => h.state)
      .filter((value, index, self) => self.indexOf(value) === index)

    allStates.map(state => {
      const seats = this.props.lh
        .filter(h => h.state === state)
        .filter(h => h.title !== '')
        .filter(h => !hiddenHTVs.includes(h.slug))
        .sort((a, b) => (a.seat > b.seat ? 1 : -1))

      seats.map(h => {
        this.setState(prevState => ({
          allElectorates: [
            ...prevState.allElectorates,
            {
              slug: h.slug,
              seat: h.seat,
              state: h.state
            }
          ]
        }))
      })

      const upHouse = this.props.uh && this.props.uh.filter(u => u.state === state)

      if (upHouse.length > 0) {
        this.setState(prevState => ({
          allStates: [
            ...prevState.allStates,
            {
              slug: upHouse[0].slug,
              seat: upHouse[0].seat,
              state: upHouse[0].state
            }
          ]
        }))
      }
    })
  }

  handleChange = e => {
    const value = e.target.value
    if (value.length <= 4) {
      this.setState({ userPostCode: value })
    }
    this.setState({ invalidPostCode: false })
  }

  handleSubmit = e => {
    e.preventDefault()
    const url = `/.netlify/functions/electorates_by_postcode?postcode=${
      this.state.userPostCode
    }`
    this.setState({ isLoading: true })
    fetch(url, {
      // mode: 'cors',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error(`${res.status}`)
        }
      })
      .then(data => {
        let userElectorate
        // check if there's a lower house seat
        for (let i = 0; i < data.length; i++) {
          userElectorate = this.state.allElectorates.filter(
            h => h.seat.toLowerCase() === data[i].electorate.toLowerCase()
          )
          if (userElectorate.length > 0) break
        }
        // if there's no lower house, check for senate
        if (userElectorate.length === 0) {
          userElectorate = this.state.allStates.filter(
            h => h.state.toLowerCase() === data[0].state.toLowerCase()
          )
        }
        // redirect
        if (userElectorate.length > 0) {
          navigate(`/howtovote/${userElectorate[0].slug}`)
        } else {
          throw Error("Couldn't find electorate")
        }
        this.setState({ isLoading: false })
      })
      .catch(() => {
        this.setState({ invalidPostCode: true })
        this.setState({ isLoading: false })
        return console.error
      })
  }

  render () {
    return (
      <div className='home-search-box mb-4'>
        <form className='home-search-box-form' onSubmit={e => this.handleSubmit(e)}>
          <input
            type='number'
            placeholder='Your postcode'
            className={`mb-0 ${this.state.invalidPostCode && 'invalid'}`}
            onChange={this.handleChange}
            value={this.state.userPostCode}
          />
          <button
            type='submit'
            href='https://getup.org.au'
            className={`btn btn-secondary btn-large ml-2 ${this.state.isLoading &&
              'is-loading'}`}
            style={{ backgroundImage: this.state.isLoading && `url(${Spinner})` }}>
            Search
          </button>
        </form>
        {this.state.invalidPostCode && (
          <span className='search-box-error mt-2'>
            Sorry! We couldn't find your electorate
          </span>
        )}
      </div>
    )
  }
}
