import React from 'react'
import PropTypes from 'prop-types'

import Base from './Base'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

// import '../styles/motion.min.css'
// import './layout.scss'

const Layout = ({ children }) => (
  <Base>
    <Nav />
    <div className=''>
      {children}
    </div>
    <Footer />
  </Base>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
