import React from 'react'
import RecruitModal from '../components/RecruitModal'

export const FullWidthContainer = ({ className, children }) => (
  <div className={`default-hero ${className || ''}`}>
    <div className='default-hero-text'>
      {children}
    </div>
  </div>
)

export const FullWidthImage = ({ className, children }) => (
  <div className={`full-width ${className || ''}`}>
    {children}
  </div>
)

export const Header = ({ className, children, ...props }) => (
  <h2 className={`h1 header mb-3 ${className || ''}`} {...props}>{children}</h2>
)

export const Button = ({ href, className, arrowColor, children }) => (
  <a href={href} className={`btn ${className || ''}`}>
    <div className='btn-wrapper'>
      {children}
      <ButtonArrow fillColor={arrowColor} />
    </div>
  </a>
)

export const ButtonArrow = ({ width, height, fillColor }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='100%'
    height='100%'
    className='btn-arrow'
    viewBox='0 0 16 14'>
    <path fill={fillColor} d='M11.9,6L7.9,2.1l1.4-1.5L16,7l-6.8,6.4l-1.4-1.5L12,8H0V6L11.9,6L11.9,6z' />
  </svg>
)

export const FooterCTA = ({ href, module }) => (
  <div className='footer-cta'>
    <h2 className='footer-cta-heading'>Ready to go?</h2>
    <div className='footer-cta-form'>
      {/* <input type='text' placeholder='Email address' className='footer-cta-form-input' />
      <Button className='btn-secondary' arrowColor='white'> Get involved! </Button> */}
      {/* <Modal buttonText="Yes, I'm in!" className='btn-secondary btn-large' /> */}
      {/* <Modal
        href={href}
        buttonText="Yes, I'm in!"
        className='btn-secondary btn-large btn-primary-recruit' /> */}
      <RecruitModal
        module={module}
        buttonText="Yes, I'm in!"
        heading='Join the campaign'
        button='Count me in'
        className='btn-secondary btn-large btn-primary-recruit' />

    </div>
  </div>
)
